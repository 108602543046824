import { init, setExtras, setTags, setUser } from '@sentry/browser';
import { CaptureConsole, ExtraErrorData } from '@sentry/integrations';

const SENTRY_OPTIONS_ID = 'rp-sentry-options';
const SENTRY_USER_ID = 'rp-sentry-user';
const SENTRY_TAGS_ID = 'rp-sentry-tags';
const SENTRY_EXTRA_ID = 'rp-sentry-extra';

function getJson(id, defaultValue) {
  const json = document.getElementById(id)?.textContent
  if (json) {
    return JSON.parse(json);
  }

  return defaultValue;
}

init({
  ...getJson(SENTRY_OPTIONS_ID, {}),
  integrations: [
    new CaptureConsole({ levels: ['warn', 'error'] }),
    new ExtraErrorData()
  ],
  ignoreErrors: [
    /^Initial messages API call failed$/ // HubSpot integration spams this a lot
  ]
});

setUser(getJson(SENTRY_USER_ID, null));
setTags(getJson(SENTRY_TAGS_ID, {}));
setExtras(getJson(SENTRY_EXTRA_ID, {}));
